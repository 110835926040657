<template>
  <div class="apply-for-new-password">
    <div class="container-fluid blank">
      <div id="apply-for-new-password-row" class="row justify-content-center align-items-center blank-section">
        <div id="apply-for-new-password-column">
          <div id="apply-for-new-password-box" class="col-12">
            <h3 class="text-center text-info">Passwort zurücksetzen</h3>

            <div class="form-group text-center">
              <span class="text">
                Um dein Passwort zurückzusetzen, gebe bitte deine E-Mail Adresse ein. Du erhältst anschließend eine E-Mail mit einem Link, mit dem du dein Passwort zurückzusetzen kannst.
              </span>
            </div>

            <div class="form-group">
              <div class="inner-addon left-addon" style="width: 100%;">
                <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">email</i>
                <input type="text" class="form-control form-control-rtl" @blur="validateEmailInput(), checkIfExists()" v-model="credentials.email" placeholder="E-Mail">
                <span class="validation-error" v-show="showEmailExistsError">Es gibt keinen Account mit dieser E-Mail Adresse</span>
                <span class="validation-error" v-show="showEmailError">Gib deine E-Mail Adresse an</span>
              </div>
            </div>

            <div class="form-group">
              <button @click="handleApplyForPassword" style="width: 100%;" class="btn btn-hover btn-fill btn-focus-design" title="">Passwort ändern</button>
            </div>

            <div class="apply-for-new-password-row">
              <div class="text-center">Noch nicht dabei?</div>
              <router-link style="width: 100%;" :to="{ name: 'Signup'}">
               Jetzt einen Account angelegen
             </router-link>
           </div>
         </div>
       </div>
     </div>
   </div>

 </div>
</template>

<script>
  import { validEmail } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { applyResetPassword, csrf, emailExists } from '@/api/auth';

  export default {
    name: 'password-apply',
    data() {
      return {
       credentials: {
        email: "",
        appId: this.$appId,
        appUrl: window.location.origin,
      },
      showEmailExistsError: false,
      showEmailError: false,
      loader: false,
    };
  },
  methods: {
    showLoader,
    hideLoader,
    validEmail,
    validateEmailInput(){
      if(!validEmail(this.credentials.email)){
        this.showEmailError=true;
        return false;
      }
      else{
        this.showEmailError=false;
        return true;
      }
    },
    checkIfExists(){
      var theMail = {email: this.credentials.email};
      emailExists(theMail).then(response => {
        if(response.status == "204"){
          /*no user*/
          this.showEmailExistsError = true;
        }
        else if(response.status == "200"){
          /*a user exists*/
          this.showEmailExistsError = false;
        }
      });
    },
    handleApplyForPassword() {

     if(this.validateEmailInput() && !this.showEmailExistsError){

      this.loader = this.showLoader(this.loader);

      csrf().then(() => {
        applyResetPassword(this.credentials).then(() => {

          this.$notify({
            duration: 2500,
            title: "Passwort zurückgesetzt!",
            text: "Wir haben dir eine Mail mit einem Link geschickt, mit dem du dein Passwort zurückzusetzen kannst."
          });

          this.$router.push({name:'Home'});
          this.loading = false;
        })
        .catch( error => {

          var message = error.response.data;
          this.$notify({
            duration: 4500,
            type: 'error',
            title: "Fehler beim Zurücksetzen des Passworts!",
            text: message
          });

        }).finally(()=>{
          this.loader = this.hideLoader(this.loader);
        });
      });
    }
  },
}
};
</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .validation-error {
    color: red;
  }

  .apply-for-new-password {
    position: relative;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 40px;

    .blank{
      display: flex;
      align-items: center;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #apply-for-new-password-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 30px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 9px;
          pointer-events: none;
          color: #157FAC;
        }

        .left-addon input {
          padding-left: 40px;
        }
      }
    }
  }

  .btn-focus-design:hover{
    background-color: #203B73;
  }
</style>
